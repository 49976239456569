import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
// import '../../styles/components/links.css'

const Certificates = () => (
  <section className="pt-8 pb-16">
    <div className="container max-w-3xl px-3">
      <h2 className="text-center text-4xl mb-8">شهاداتي التقنية</h2>
      <p className="text-center text-2xl mb-8">
        لمزيد من المعلومات عن هذه الشهادات يمكنك الاطلاع على حسابي في{' '}
        <a
          href="https://www.linkedin.com/in/watheq-show/"
          rel="noreferrer"
          target="_blank"
        >
          Linkedin
        </a>
        .
      </p>
      <div class="container mx-auto px-4">
        <section class="py-8 px-4">
          <div class="flex flex-wrap -mx-4">
            <div class="md:block md:w-1/2 px-4 mb-4">
              <div class="h-full w-full bg-cover rounded shadow-md">
                <StaticImage
                  class="rounded shadow-md"
                  src="../../images/certificates/Tage.jpeg"
                  alt=""
                />
              </div>
            </div>
            <div class="md:w-1/2 h-auto px-4">
              <div class="mb-4">
                <StaticImage
                  class="rounded shadow-md"
                  src="../../images/certificates/google-clone-udemy.png"
                  alt=""
                />
              </div>
              <div class="mb-4">
                <StaticImage
                  class="rounded shadow-md"
                  src="../../images/certificates/recoded-frontend.png"
                  alt=""
                />
              </div>
            </div>
            <div class="w-full h-auto px-4 py-4 mb-4">
              <StaticImage
                class="rounded shadow-md"
                src="../../images/certificates/flatiron-frontend.png"
                alt=""
              />
            </div>
            <div class="h-auto px-4 md:flex">
              <div class="mb-4 md:ml-4">
                <StaticImage
                  class="rounded shadow-md"
                  src="../../images/certificates/Responsive-Web-Design-freeCodeCamp.png"
                  alt=""
                />
              </div>
              <div className="mb-4">
                <StaticImage
                  class="rounded shadow-md"
                  src="../../images/certificates/JavaScript-Algorithms-Data-feeCoedCamp.png"
                  alt=""
                />
              </div>
            </div>
            <div class="h-auto px-4 md:flex">
              <div class="mb-4 md:ml-4">
                <StaticImage
                  class="rounded shadow-md"
                  src="../../images/certificates/SQL-Basic-Certificate.jpeg"
                  alt=""
                />
              </div>
              <div className="mb-4">
                <StaticImage
                  class="rounded shadow-md"
                  src="../../images/certificates/SQL-Intermediate-Certificate.jpeg"
                  alt=""
                />
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </section>
)

export default Certificates
