import React from 'react'
import { graphql } from 'gatsby'
import Seo from '../components/Seo/Seo'
import useSiteMetadata from '../hooks/use-site-metadata'
import About from '../components/Resume/About'
import Skill from '../components/Resume/Skill'
import Experience from '../components/Resume/Experience'
import Education from '../components/Resume/Education'
import Hobbies from '../components/Resume/Hobbies'
import Projects from '../components/Resume/Projects'
import Certificates from '../components/Resume/Certificates'

const Home = ({ location, data: { file } }) => {
  const siteMetadata = useSiteMetadata()

  return (
    <>
      <Seo description={siteMetadata.description} path={location.pathname} />

      <About file={file} />
      <Skill />
      <Experience />
      <Education />
      <Projects />
      <Certificates />
      <Hobbies />
    </>
  )
}

export default Home
export const query = graphql`
  {
    file(relativePath: { eq: "me.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 128, quality: 100)
      }
    }
  }
`
